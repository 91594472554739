<template>
  <div class="container bg-white p-10">
    <div class="header">
      手机绑定
    </div>
    <div class="text">
      验证账号
    </div>
    <!-- <div v-if="isShow">
            <div class="form">
                <div class="texts">
                     &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 用户名：<span>{{ userInfo.username.slice(0, 3) }}****{{ userInfo.username.slice(7, 11) }}</span>
                </div>
                <div class="input">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span>*</span>
                    手机验证码：<input type="text" placeholder="请输入验证码" v-model="code">
                    <div class="btn">发送验证码</div>
                </div>
            </div>
            <div class="next" @click="handleNext">
                下一步
            </div>
        </div> -->
    <div>
      <div class="form">
        <div class="texts">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; 用户名：<span>{{ userInfo.username.slice(0, 3) }}****{{
            userInfo.username.slice(7, 11)
          }}</span>
        </div>
        <br>
        <div class="input1">
          &nbsp; &nbsp; &nbsp; <span>*</span> 请输入账号密码：<input v-model="pwd" placeholder="请输入账号密码" type="password">
        </div>
        <br>
        <div class="input1">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span>*</span>请输入新号码： <input v-model="phone" placeholder="请输入新号码"
            type="text">
        </div>
        <br>
        <div class="input">
          &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span>*</span>
          手机验证码：<input v-model="code" placeholder="请输入验证码" type="text">
          <el-button :disabled="status === 1" :type="status === 1 ? 'info' : 'primary'" size="small"
            style="margin-left: 25px" @click="getCode">{{ codeText }}</el-button>
        </div>
      </div>
      <div class="next" @click="handleNext">
        确认修改
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getEditPhone, getEditUserPhone } from '@/api/Login'
export default {
  name: 'Edit',
  data() {
    return {
      uuid: 0,
      index: 0,
      pwd: '',
      phone: '',
      code: '',
      status: 0,  // 0 获取验证码 1 已发送{count}秒 2 重新发送
      count: '',
      timer: null,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    codeText() {
      if (this.status === 1) {
        return `已发送 ${this.count}秒`
      } else if (this.status === 2) {
        return `重新发送`
      } else {
        return `获取验证码`
      }
    }
  },
  created() {
    this.index = this.$route.params.id
  },
  methods: {
    LogOut() {
      this.$store.dispatch('FedLogOut').then(() => {
        this.$router.push({ path: '/login' })
      })
    },
    handleNext() {
      let that = this
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.phone)) {
        return this.$message.error('手机号校验不通过')
      }

      if (this.code !== '' && this.newPhone !== '' && this.uuid !== '' && this.oldPhone !== '') {
        const queryData = {
          code: this.code,
          newPhone: this.phone,
          oldPhone: this.userInfo.username,
          uuid: this.uuid
        }

        getEditUserPhone(queryData).then((res) => {
          this.$confirm('手机号绑定成功，请重新登录', '提示', {
            confirmButtonText: '确定',
            showCancelButton: false,
            showClose: false,
            type: 'success'
          }).then(() => {
            that.LogOut()
          })
        }).catch(e => e)
      } else {
        this.$message.error('信息添加不完整')
      }

    },
    getCode() {
      if (!(this.phone && this.pwd)) {
        return this.$message.error('账号密码或者新号码不允许为空')
      }
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.phone)) {
        return this.$message.error('手机号校验不通过')
      }
      const times = 60 // 倒计时时间
      if (!this.times) {
        getEditPhone(this.userInfo.username).then((res) => {
          this.uuid = res.data
        }).catch(() => {
          this.status = 2
          clearInterval(this.timer)
          this.timer = null
        })
        this.count = times
        this.status = 1
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= times) {
            this.count--
          } else {
            this.status = 2
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
  }
}

</script>
<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 650px;
  background-color: #fff;
  padding: 40px 250px 0 250px;

  .header {
    text-align: left;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    background: #0962ea;
    box-shadow: 0 3px 20px rgba(9, 98, 234, 0.16);
    font-family: "PingFang SC Bold";
    font-weight: 700;
    font-size: 14px;
    color: #fff;
  }

  .text {
    font-family: "PingFang SC Bold";
    font-weight: 700;
    font-size: 12;
    color: #000;
    margin: 30px 0 0 20px;
  }

  .form {
    margin: auto;
    width: 400px;
    // border: 1px solid red;

    .texts {
      margin: 10px 0;
      width: 440px;
      padding-left: 35px;
      // border: 1px solid red;

      span {
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        color: #00c48c;
      }
    }

    .input {
      margin: 10px 0;
      width: 540px;
      // border: 1px solid red;
      display: flex;
      align-items: center;

      input {
        border: none;
        outline: none;
        width: 223px;
        height: 40px;
        padding-left: 5px;
        background-color: #f6f6f6;
      }

      .btn {
        width: 94px;
        height: 34px;
        border-radius: 4px;
        background: #0962ea;
        line-height: 34px;
        text-align: center;
        color: #fff;
        margin-left: 20px;
        cursor: pointer;
      }

      span {
        color: #FF0000;
      }
    }

    .input1 {
      display: flex;
      align-items: center;

      input {
        border: none;
        outline: none;
        width: 223px;
        height: 40px;
        padding-left: 5px;
        background-color: #f6f6f6;
      }

      span {
        color: #FF0000;
      }
    }
  }

  .next {
    margin: 120px auto;
    width: 218px;
    height: 42px;
    border-radius: 7px;
    background: #bb3c72;
    text-align: center;
    line-height: 42px;
    color: #fff;
    cursor: pointer;
  }
}
</style>
